<template>
  <div class="main__dashboard">
      <!-- Start of top hearder component -->
      <div class="header">
          <div class="header__logo">
              <img src="../../assets/afcfta_caravan.png" height="60" />
          </div>
          <div class="header__searchbar">
              <input class="input" type="text" placeholder="search" >
              <button class="button"><i class="fas fa-search"></i></button>
          </div>
          <div class="header__links">
              <ul>
                  <li><a href="">Blog</a></li>
                  <li><a href="">Debate</a></li>
                  <li><a href="">Challenge</a></li>
              </ul>
          </div>
          <div class="header__icons">
              <button><i class="far fa-envelope"></i></button>
              <button><i class="fas fa-bell mr-4"></i></button>
              <div class="avatar">
                  <p class="avatar__text pr-3">Abdul Malik</p>
                  <div class="avatar__avatar mt-1">

                  </div>
              </div>
          </div>
      </div>
    <!-- End of top header -->
      <div class="header__content">
          <div class="header__content__sidebar">
              <h2 class="header__content__sidebar__header">AfCFTA Caravan</h2>
              <div class="header__content__sidebar__links">
                  <ul>
                      <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                       <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                      <li><a href="">Home</a></li>
                   
                  </ul>
                   <a href=""><i class="fas fa-sign-out-alt mr-3"></i>Logout</a>
              </div>
          </div>
          <div class="header__dashboard__content">
              <div class="row">
                  <div class="col-md-4">

                  </div>
                  <div class="col-md-4">

                  </div>
                  <div class="col-md-4">

                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
 
}
</script>

<style scoped>

.main__dashboard{
    margin:0;
    padding:0;
    width:100%;
}
/* 
 * Header component
 *
 */
.header{
    background:#171717;
    color:#ebebeb;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:1em 5%; 
    width:100%;
    position:sticky;
    z-index: 999;
    top:0;
    left: 0;
    right:0;

}
.sticky {
  position: fixed;
  top: 0;
  width: 100%
}
/* 
 * Search bar content
 *
 */
.header .header__searchbar{
    position:relative;
    display: flex;
    background-color:#4a4a4a;
    height: 40px;
    border-radius: 20px;
}
.header .header__searchbar .input{
    border:none;
    outline:none;
    height: 40px;
    border:none;
    outline:none;
    width: 100%;
    border-radius: 20px;
    background-color:transparent;
    font-size: 1.3em;
    padding-left: 1em;
    color:#ebebeb;
    display: block;
}
.header .header__searchbar .button{
    border:none;
    outline:none;
    position:absolute;
    background-color:transparent;
    top:0;
    right: 0;
    font-size: 1.3em;
    color:#ebebeb;
    padding-top:.3em;
    padding-right:1em;
    }
/* 
 * Links content
 *
 */
.header .header__links ul {
    list-style: none;
    padding-top:1em;
}
.header .header__links ul li{
    display: inline;
    color:#ebebeb;
    padding-right: .5em;
}
.header .header__links ul li a{
    text-decoration: none;
    color:#ebebeb;
}
/* 
 * header icons
 *
 */
.header .header__icons{
    display: flex;
    flex-direction: row;
}
.header .header__icons button{
    background-color:transparent;
    color:#ebebeb;
    border:none;
    outline:none;
    font-size:1.3em !important;
}
.header .header__icons .avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.header .header__icons .avatar .avatar__avatar {
    width:40px;
    height: 40px;
    background-color:#fff;
    border-radius:20px;
}
.header .header__icons .avatar .avatar__text {
    padding-top:1em;
}
/* 
 * content
 */

.header__content {
    width:100%;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 100px);
}

/* sid */
 .header__content__sidebar {
    background-color: #fff;
    width:400px;
    display: flex;
    flex-direction: column;
}
.header__content__sidebar__header {
    font-size:1.5em; 
    color:#000; 
    font-weight: bolder;
    text-align: center;
    padding-top: 2em;
    padding-bottom: 1em;
}

.header__dashboard__content{
    padding: 3em 5%;
    background-color:#efefef;
}

/* sidebar */
/* 
 * media queries for top header
 */
 @media screen and (max-width:900px) {
     .header .header__links{
         display: none;
     }
 }
 </style>